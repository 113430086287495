import React from 'react';

const ReplayIcon: React.FC = () => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00003 4.00052V1.21052C8.00003 0.760519 7.46003 0.540519 7.15003 0.860519L3.35003 4.65052C3.15003 4.85052 3.15003 5.16052 3.35003 5.36052L7.14003 9.15052C7.46003 9.46052 8.00003 9.24052 8.00003 8.79052V6.00052C11.73 6.00052 14.68 9.42052 13.86 13.2905C13.39 15.5605 11.55 17.3905 9.29003 17.8605C5.72003 18.6105 2.54003 16.1605 2.06003 12.8505C1.99003 12.3705 1.57003 12.0005 1.08003 12.0005C0.480028 12.0005 2.79889e-05 12.5305 0.080028 13.1305C0.700028 17.5205 4.88003 20.7705 9.61003 19.8505C12.73 19.2405 15.24 16.7305 15.85 13.6105C16.84 8.48052 12.94 4.00052 8.00003 4.00052Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default ReplayIcon;
