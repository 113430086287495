import { proxy, useSnapshot } from 'valtio';

const state = proxy({ isInSigilDashboard: false });

export const useIsInSigilDashboard = () => {
  const isInSigilDashboard = useSnapshot(state).isInSigilDashboard;
  return isInSigilDashboard;
};

export const setIsInSigilDashboard = (isInSigilDashboard: boolean) => {
  state.isInSigilDashboard = isInSigilDashboard;
};
