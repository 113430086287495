export const socialLinks = {
  discord: 'http://discord.gg/myria',
  twitter: 'https://twitter.com/myria',
  instagram: 'https://www.instagram.com/myriagames/',
  medium: 'https://medium.com/@myriagames',
  linkedin: 'https://www.linkedin.com/company/myriaworld/'
};

export const localStorageKeys = {
  language: 'LANG',
  firstTime: 'FIRST_TIME',
  referralCode: 'REFERRAL_CODE'
};
