import React from 'react';

type Props = {
  className?: string;
  size?: number;
};

export default function InfoCircleIcon({
  size = 16,
  className = 'text-[#A1AFBA]',
}: Props) {
  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00032 0.833659C11.9537 0.833658 15.167 4.04699 15.167 8.00033C15.167 11.9537 11.9537 15.167 8.00033 15.167C4.04699 15.167 0.833658 11.9537 0.833658 8.00033C0.833657 4.04699 4.04699 0.833659 8.00032 0.833659ZM8.00033 14.167C11.4003 14.167 14.167 11.4003 14.167 8.00033C14.167 4.60033 11.4003 1.83366 8.00032 1.83366C4.60032 1.83366 1.83366 4.60033 1.83366 8.00033C1.83366 11.4003 4.60033 14.167 8.00033 14.167Z"
          fill="#9AC9E3"
        />
        <path
          d="M8 6.83366C8.27333 6.83366 8.5 7.06033 8.5 7.33366L8.5 10.667C8.5 10.9403 8.27333 11.167 8 11.167C7.72667 11.167 7.5 10.9403 7.5 10.667L7.5 7.33366C7.5 7.06033 7.72667 6.83366 8 6.83366Z"
          fill="#9AC9E3"
        />
        <path
          d="M8.00033 4.66707C8.08699 4.66707 8.17366 4.68707 8.25366 4.7204C8.33366 4.75374 8.40699 4.8004 8.47366 4.8604C8.53366 4.92707 8.58033 4.99374 8.61366 5.0804C8.64699 5.1604 8.66699 5.24707 8.66699 5.33374C8.66699 5.4204 8.64699 5.50707 8.61366 5.58707C8.58033 5.66707 8.53366 5.7404 8.47366 5.80707C8.40699 5.86707 8.33366 5.91374 8.25366 5.94707C8.09366 6.01374 7.90699 6.01374 7.74699 5.94707C7.66699 5.91374 7.59366 5.86707 7.52699 5.80707C7.46699 5.7404 7.42033 5.66707 7.38699 5.58707C7.35366 5.50707 7.33366 5.4204 7.33366 5.33374C7.33366 5.24707 7.35366 5.1604 7.38699 5.0804C7.42033 4.99374 7.46699 4.92707 7.52699 4.8604C7.59366 4.8004 7.66699 4.75374 7.74699 4.7204C7.82699 4.68707 7.91366 4.66707 8.00033 4.66707Z"
          fill="#9AC9E3"
        />
      </svg>
    </div>
  );
}

InfoCircleIcon.defaultProps = {
  size: 20,
  className: 'text-[#777777]',
};
