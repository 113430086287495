import { IThemeConfig } from '../helpers';

const lightTheme: IThemeConfig = {
  name: 'light',
  colors: {
    background: 'rgb(255, 255, 255)',
    main: 'rgb(12, 12, 13)',
    secondary: 'rgb(169, 169, 188)',
    border: 'rgba(195, 195, 195, 0.14)',
    hover: 'rgba(195, 195, 195, 0.14)'
  }
};

export default lightTheme;
